<template>
  <div>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#"><i class="fas fa-bars"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" @click.prevent="cashClear">
            <i class="fa fa-brush"></i>
            <slot v-if="loader">
                <div class="ml-1 spinner-border spinner-border-sm text-success" role="status"></div>
            </slot>
          </a>
        </li>
      </ul>
      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">        
        <li class="nav-item dropdown">
          <select v-model="locale" @change="handleChange($event)" class="custom-select">
            <option value="en" :selected="enActive">En</option>
            <option value="bn" :selected="bnActive">Bn</option>
          </select>
        </li>        
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
            <i class="fa fa-power-off"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="left: inherit; right: 0px;">
            <a href="#"  @click.prevent="logout" class="dropdown-item dropdown-footer"><i class="fas fa-sign-out-alt mr-2"></i> {{ $t('globalTrans.sign_out') }}</a>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import config from '@/config'
export default {
  name:'Navbar',   
  data () {
    return {
      loader: false,
      locale: 'en',
      inactivityTimeout: null,
      maxInactivityTime: 15 * 60 * 1000, // 15 minutes in milliseconds
    }
  },
  created () {
    this.locale = this.currentLang

    // Set up initial timer
    this.resetInactivityTimer();
    // Listen for user activity events
    window.addEventListener('mousemove', this.resetInactivityTimer);
    window.addEventListener('keydown', this.resetInactivityTimer);
  },
  beforeDestroy() {
    // Clean up event listeners
    window.removeEventListener('mousemove', this.resetInactivityTimer);
    window.removeEventListener('keydown', this.resetInactivityTimer);
    clearTimeout(this.inactivityTimeout);
  },
  computed : {
    bnActive: function () {
      return this.currentLang == 'bn' ? true : false
    },
    enActive: function () {
      return this.currentLang == 'en' ? true : false
    },
    currentLang: function () {
      return this.$store.state.language
    },
    authUser: function () {
      return this.$store.state.authUser
    }
  },
  methods:{
    handleChange (event) {
      const localeVal = event.target.value      
      this.$i18n.locale = localeVal
      this.locale = event.target.value
      this.$store.dispatch('setLang', localeVal)      
    },
    async cashClear() {
      this.loader = true
      await config.getData('/clear-cache')
      this.loader = false    
    },
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimeout);
      this.inactivityTimeout = setTimeout(this.logout, this.maxInactivityTime);
    },
    logout(){
      config.postData('/rider/logout')
      .then(() => {
        this.$store.dispatch('setAuthUser', '')  
        localStorage.removeItem('accessToken')   
        this.$router.push('/auth/login');
      })      
    }
  }
}
</script>